import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import i18n from "@/libs/i18n";

export default function useDepartment() {
  const toast = useToast();
  const membersDepartments = ref(null);

  const perPage = ref(25);
  const totalMembersDepartments = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const platformOptions = ref([]);
  const deviceOptions = ref([]);
  const deviceModelOptions = ref([]);

  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const ipFilter = ref();
  const platformFilter = ref();
  const deviceFilter = ref();
  const deviceModelFilter = ref();

  const refMembersDepartmentListTable = ref(null);

  const tableColumns = [
    { key: "index", label: "#" },
    { key: "name", label: i18n.t("User Name") },
    { key: "browser", label: i18n.t("Browser") },
    { key: "device_model", label: i18n.t("Device Model") },
    { key: "device_family", label: i18n.t("Device Family") },
    { key: "ip", label: i18n.t("Ip") },
    { key: "location", label: i18n.t("Location") },
    { key: "platform", label: i18n.t("Platform") },
    { key: "platform_name", label: i18n.t("Platform Name") },
    { key: "platform_family", label: i18n.t("Platform Family") },
    { key: "platform_version", label: i18n.t("Platform Version") },
    { key: "createdAt", label: i18n.t("Created at") },
    // { key: "updatedAt", label: i18n.t("Updated at") },
    // { key: "action", label: i18n.t("Actions") },
  ];

  const dataMeta = computed(() => {
    const localItemsCount = refMembersDepartmentListTable.value
      ? refMembersDepartmentListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMembersDepartments.value,
    };
  });

  const refetchData = () => {
    refMembersDepartmentListTable.value.refresh();
  };

  watch([currentPage, perPage], () => {
    refetchData();
  });
  const searchFilter = () => {
    refMembersDepartmentListTable.value.refresh();
  };

  const fetchMemberDepartments = (ctx, callback) => {
    store
      .dispatch("app-member/fetchAuditLogin", {
        perPage: perPage.value,
        page: currentPage.value,
        ip: ipFilter.value,
        platform: platformFilter.value,
        device: deviceFilter.value,
        device_model: deviceModelFilter.value,
      })
      .then((response) => {
        const { departments, total } = response;
        callback(departments);
        membersDepartments.value = departments;
        totalMembersDepartments.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching department list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  store.dispatch("app-member/fetchPlatform", {}).then((response) => {
    if (response.data.code == "00") {
      platformOptions.value = response.data.data;
    }
  });
  store.dispatch("app-member/fetchDevice", {}).then((response) => {
    if (response.data.code == "00") {
      deviceOptions.value = response.data.data;
      // console.log(123, deviceOptions.value);
    }
  });
  store.dispatch("app-member/fetchDeviceModel", {}).then((response) => {
    if (response.data.code == "00") {
      deviceModelOptions.value = response.data.data;
      console.log(123, deviceOptions.value);
    }
  });

  const resolveStatus = (status) => {
    if (status == 1) return { label: i18n.t("Active"), variant: "success" };
    if (status == 2) return { label: i18n.t("Inactive"), variant: "danger" };
    return { label: "None", variant: "secondary" };
  };

  return {
    tableColumns,
    perPage,
    currentPage,
    totalMembersDepartments,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refMembersDepartmentListTable,

    membersDepartments,
    ipFilter,
    platformFilter,
    deviceFilter,

    fetchMemberDepartments,
    refetchData,
    resolveStatus,
    platformOptions,
    deviceOptions,
    deviceModelOptions,
    searchFilter,
    deviceModelFilter,
  };
}
